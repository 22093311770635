<template>
  <div class="navContainer" :style="{ 'padding-bottom': paddingBtm }">
    <div class="row" style="margin-right: 0px">
      <div class="col-md-12" style="padding-right:0px">
        <nav class="navbar navbar-default navbar-mobile bootsnav">
          <!-- 小屏Nav栏 -->
          <div class="navbar-header" ref="navbarHeader">
            <img
              src="../../assets/huaiHuaMerchants-logo.jpg"
              alt="logo"
              class="logo hidden-lg"
              @click.stop="pageJump('Home')"
            />
            <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target="#navbar-menu"
            >
              <i class="fa fa-bars"></i>
            </button>
          </div>
          <!-- 展开面板 -->
          <div style="display: flex">
            <div class="collapse navbar-collapse" id="navbar-menu">
              <div
                style="display: flex; justify-content: left"
                class="website-logo"
              >
                <img
                  src="../../assets/huaiHuaMerchants-logo.jpg"
                  alt="logo"
                  class="logo hidden-xs hidden-sm"
                  @click.stop="pageJump('Home')"
                />
                <div class="website-name">广东省湖南怀化商会</div>
              </div>
              <ul
                class="nav navbar-nav"
                data-in="fadeInDown"
                data-out="fadeOutUp"
              >
                <li><a href="#" @click.stop="pageJump('Home')">首页</a></li>
                <li>
                  <a href="#" @click.stop="pageJump('Humanities')">走进怀化</a>
                </li>
                <li class="dropdown">
                  <a href="#" class="dropdown-toggle" data-toggle="dropdown"
                    >怀商新闻</a
                  >

                  <ul class="dropdown-menu">
                    <li v-for="(item, index) in resdata" :key="index">
                      <a @click.stop="pageJump(item)">{{
                        item.categoryTitle
                      }}</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#" @click.stop="showInfo()"
                    ><!--@click.stop="pageJump('Optimization')"-->
                    怀商优选
                  </a>
                </li>
                <li class="dropdown">
                  <a href="#" class="dropdown-toggle" data-toggle="dropdown"
                    >资源共享</a
                  >

                  <ul class="dropdown-menu">
                    <li>
                      <a href="#" @click.stop="pageJump('members')"
                        >会员供需发布</a
                      >
                    </li>
                    <li>
                      <a href="#" @click.stop="pageJump('UnitIntroduce')"
                        >会员资源共享</a
                      >
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#" @click.stop="pageJump('AdmissionNotice')">
                    申请入会
                  </a>
                </li>
                <li class="dropdown">
                  <a href="#" class="dropdown-toggle" data-toggle="dropdown"
                    >关于我们</a
                  >
                  <ul class="dropdown-menu">
                    <li>
                      <a href="#" @click.stop="pageJump('Brief')">商会简介</a>
                    </li>
                    <li>
                      <a href="#" @click.stop="pageJump('Constitution')"
                        >商会章程</a
                      >
                    </li>
                    <li>
                      <a href="#" @click.stop="pageJump('OurStyle')"
                        >会员风采</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import Qs from "qs";
import { Huaishangnews } from "@/api/fetch";
export default {
  name: "MainNav",
  data() {
    return {
      resdata: [],
      resmember: [],
      paddingBtm: 0,
    };
  },
  mounted() {
    this.dataAPIlist();
    this.paddingBtm = this.$refs.navbarHeader
      ? this.$refs.navbarHeader.clientHeight
      : 0;
    this.$nextTick(function () {
      this.initialize();
      window.addEventListener("resize", this.handleResize, true);
      window.addEventListener("scroll", this.navbarSticky, true);
    });
  },
  methods: {
    dataAPIlist() {
      let obj = {
        categoryId: "1",
      };
      Huaishangnews(Qs.stringify(obj)).then((res) => {
        this.resdata = res.data.data.rows;
      });
    },
    initialize() {
      this.event();
      this.hoverDropdown();
      this.navbarSticky();
      this.navbarScrollspy();
    },
    event() {
      // ------------------------------------------------------------------------------ //
      // Variable
      // ------------------------------------------------------------------------------ //
      var getNav = $("nav.navbar.bootsnav");

      // ------------------------------------------------------------------------------ //
      // Navbar Sticky
      // ------------------------------------------------------------------------------ //
      var navSticky = getNav.hasClass("navbar-sticky");
      if (navSticky) {
        // Wraped navigation
        getNav.wrap("<div class='wrap-sticky'></div>");
      }

      // ------------------------------------------------------------------------------ //
      // Navbar Center
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("brand-center")) {
        var postsArr = new Array(),
          index = $("nav.brand-center"),
          $postsList = index.find("ul.navbar-nav");

        index.prepend(
          "<span class='storage-name' style='display:none;'></span>"
        );

        //Create array of all posts in lists
        index.find("ul.navbar-nav > li").each(function () {
          if ($(this).hasClass("active")) {
            var getElement = $("a", this).eq(0).text();
            $(".storage-name").html(getElement);
          }
          postsArr.push($(this).html());
        });

        //Split the array at this point. The original array is altered.
        var firstList = postsArr.splice(0, Math.round(postsArr.length / 2)),
          secondList = postsArr,
          ListHTML = "";

        var createHTML = function (list) {
          ListHTML = "";
          for (var i = 0; i < list.length; i++) {
            ListHTML += "<li>" + list[i] + "</li>";
          }
        };

        //Generate HTML for first list
        createHTML(firstList);
        $postsList.html(ListHTML);
        index.find("ul.nav").first().addClass("navbar-left");

        //Generate HTML for second list
        createHTML(secondList);
        //Create new list after original one
        $postsList
          .after('<ul class="nav navbar-nav"></ul>')
          .next()
          .html(ListHTML);
        index.find("ul.nav").last().addClass("navbar-right");

        //Wrap navigation menu
        index
          .find("ul.nav.navbar-left")
          .wrap("<div class='col-half left'></div>");
        index
          .find("ul.nav.navbar-right")
          .wrap("<div class='col-half right'></div>");

        //Selection Class
        index.find("ul.navbar-nav > li").each(function () {
          var dropDown = $("ul.dropdown-menu", this),
            megaMenu = $("ul.megamenu-content", this);
          dropDown.closest("li").addClass("dropdown");
          megaMenu.closest("li").addClass("megamenu-fw");
        });

        var getName = $(".storage-name").html();
        if (!getName == "") {
          $("ul.navbar-nav > li:contains('" + getName + "')").addClass(
            "active"
          );
        }
      }

      // ------------------------------------------------------------------------------ //
      // Navbar Sidebar
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("navbar-sidebar")) {
        // Add Class to body
        $("body").addClass("wrap-nav-sidebar");
        getNav.wrapInner("<div class='scroller'></div>");
      } else {
        $(".bootsnav").addClass("on");
      }

      // ------------------------------------------------------------------------------ //
      // Menu Center
      // ------------------------------------------------------------------------------ //
      if (getNav.find("ul.nav").hasClass("navbar-center")) {
        getNav.addClass("menu-center");
      }

      // ------------------------------------------------------------------------------ //
      // Navbar Full
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("navbar-full")) {
        // Add Class to body
        $("nav.navbar.bootsnav")
          .find("ul.nav")
          .wrap("<div class='wrap-full-menu'></div>");
        $(".wrap-full-menu").wrap("<div class='nav-full'></div>");
        $("ul.nav.navbar-nav").prepend(
          "<li class='close-full-menu'><a href='#'><i class='el-icon-s-fold'></i></a></li>"
        );
      } else if (getNav.hasClass("navbar-mobile")) {
        getNav.removeClass("no-full");
      } else {
        getNav.addClass("no-full");
      }

      // ------------------------------------------------------------------------------ //
      // Navbar Mobile
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("navbar-mobile")) {
        // Add Class to body
        $(".navbar-collapse").on("shown.bs.collapse", function () {
          $("body").addClass("side-right");
        });
        $(".navbar-collapse").on("hide.bs.collapse", function () {
          $("body").removeClass("side-right");
        });

        $(window).on("resize", function () {
          $("body").removeClass("side-right");
        });
      }

      // ------------------------------------------------------------------------------ //
      // Navbar Fixed
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("no-background")) {
        $(window).on("scroll", function () {
          var scrollTop = $(window).scrollTop();
          if (scrollTop > 34) {
            $(".navbar-fixed").removeClass("no-background");
          } else {
            $(".navbar-fixed").addClass("no-background");
          }
        });
      }

      // ------------------------------------------------------------------------------ //
      // Navbar Fixed
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("navbar-transparent")) {
        $(window).on("scroll", function () {
          var scrollTop = $(window).scrollTop();
          if (scrollTop > 34) {
            $(".navbar-fixed").removeClass("navbar-transparent");
          } else {
            $(".navbar-fixed").addClass("navbar-transparent");
          }
        });
      }

      // ------------------------------------------------------------------------------ //
      // Button Cart
      // ------------------------------------------------------------------------------ //
      $(".btn-cart").on("click", function (e) {
        e.stopPropagation();
      });

      // ------------------------------------------------------------------------------ //
      // Toggle Search
      // ------------------------------------------------------------------------------ //
      $("nav.navbar.bootsnav .attr-nav").each(function () {
        $("li.search > a", this).on("click", function (e) {
          e.preventDefault();
          $(".top-search").slideToggle();
        });
      });
      $(".input-group-addon.close-search").on("click", function () {
        $(".top-search").slideUp();
      });

      // ------------------------------------------------------------------------------ //
      // Toggle Side Menu
      // ------------------------------------------------------------------------------ //
      $("nav.navbar.bootsnav .attr-nav").each(function () {
        $("li.side-menu > a", this).on("click", function (e) {
          e.preventDefault();
          $("nav.navbar.bootsnav > .side").toggleClass("on");
          $("body").toggleClass("on-side");
        });
      });
      $(".side .close-side").on("click", function (e) {
        e.preventDefault();
        $("nav.navbar.bootsnav > .side").removeClass("on");
        $("body").removeClass("on-side");
      });

      // ------------------------------------------------------------------------------ //
      // Wrapper
      // ------------------------------------------------------------------------------ //
      $("body").wrapInner("<div class='wrapper'></div>");
    },
    hoverDropdown() {
      var getNav = $("nav.navbar.bootsnav"),
        getWindow = $(window).width(),
        getHeight = $(window).height(),
        getIn = getNav.find("ul.nav").data("in"),
        getOut = getNav.find("ul.nav").data("out");

      if (getWindow < 991) {
        // Height of scroll navigation sidebar
        $(".scroller").css("height", "auto");

        // Disable mouseenter event
        $("nav.navbar.bootsnav ul.nav").find("li.dropdown").off("mouseenter");
        $("nav.navbar.bootsnav ul.nav").find("li.dropdown").off("mouseleave");
        $("nav.navbar.bootsnav ul.nav").find(".title").off("mouseenter");
        $("nav.navbar.bootsnav ul.nav").off("mouseleave");
        $(".navbar-collapse").removeClass("animated");

        // Enable click event
        $("nav.navbar.bootsnav ul.nav").each(function () {
          $(".dropdown-menu", this).addClass("animated");
          $(".dropdown-menu", this).removeClass(getOut);

          // Dropdown Fade Toggle
          $("a.dropdown-toggle", this).off("click");
          $("a.dropdown-toggle", this).on("click", function (e) {
            e.stopPropagation();
            $(this)
              .closest("li.dropdown")
              .find(".dropdown-menu")
              .first()
              .stop()
              .fadeToggle()
              .toggleClass(getIn);
            $(this).closest("li.dropdown").first().toggleClass("on");
            return false;
          });

          // Hidden dropdown action
          $("li.dropdown", this).each(function () {
            $(this).find(".dropdown-menu").stop().fadeOut();
            $(this).on("hidden.bs.dropdown", function () {
              $(this).find(".dropdown-menu").stop().fadeOut();
            });
            return false;
          });

          // Megamenu style
          $(".megamenu-fw", this).each(function () {
            $(".col-menu", this).each(function () {
              $(".content", this).addClass("animated");
              $(".content", this).stop().fadeOut();
              $(".title", this).off("click");
              $(".title", this).on("click", function () {
                $(this)
                  .closest(".col-menu")
                  .find(".content")
                  .stop()
                  .fadeToggle()
                  .addClass(getIn);
                $(this).closest(".col-menu").toggleClass("on");
                return false;
              });

              $(".content", this).on("click", function (e) {
                e.stopPropagation();
              });
            });
          });
        });

        // Hidden dropdown
        var cleanOpen = function () {
          $("li.dropdown", this).removeClass("on");
          $(".dropdown-menu", this).stop().fadeOut();
          $(".dropdown-menu", this).removeClass(getIn);
          $(".col-menu", this).removeClass("on");
          $(".col-menu .content", this).stop().fadeOut();
          $(".col-menu .content", this).removeClass(getIn);
        };

        // Hidden om mouse leave
        $("nav.navbar.bootsnav").on("mouseleave", function () {
          cleanOpen();
        });

        // Enable click atribute navigation
        $("nav.navbar.bootsnav .attr-nav").each(function () {
          $(".dropdown-menu", this).removeClass("animated");
          $("li.dropdown", this).off("mouseenter");
          $("li.dropdown", this).off("mouseleave");
          $("a.dropdown-toggle", this).off("click");
          $("a.dropdown-toggle", this).on("click", function (e) {
            e.stopPropagation();
            $(this)
              .closest("li.dropdown")
              .find(".dropdown-menu")
              .first()
              .stop()
              .fadeToggle();
            $(".navbar-toggle").each(function () {
              $(".fa", this).removeClass("fa-times");
              $(".fa", this).addClass("fa-bars");
              $(".navbar-collapse").removeClass("in");
              $(".navbar-collapse").removeClass("on");
            });
          });

          $(this).on("mouseleave", function () {
            $(".dropdown-menu", this).stop().fadeOut();
            $("li.dropdown", this).removeClass("on");
            return false;
          });
        });

        // Toggle Bars
        $(".navbar-toggle").each(function () {
          $(this).off("click");
          $(this).on("click", function () {
            $(".fa", this).toggleClass("fa-bars");
            $(".fa", this).toggleClass("fa-times");
            cleanOpen();
          });
        });
      } else if (getWindow > 991) {
        // Height of scroll navigation sidebar
        $(".scroller").css("height", getHeight + "px");

        // Navbar Sidebar
        if (getNav.hasClass("navbar-sidebar")) {
          // Hover effect Sidebar Menu
          $("nav.navbar.bootsnav ul.nav").each(function () {
            $("a.dropdown-toggle", this).off("click");
            $("a.dropdown-toggle", this).on("click", function (e) {
              e.stopPropagation();
            });

            $(".dropdown-menu", this).addClass("animated");
            $("li.dropdown", this).on("mouseenter", function () {
              $(".dropdown-menu", this).eq(0).removeClass(getOut);
              $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
              $(this).addClass("on");
              return false;
            });

            $(".col-menu").each(function () {
              $(".content", this).addClass("animated");
              $(".title", this).on("mouseenter", function () {
                $(this)
                  .closest(".col-menu")
                  .find(".content")
                  .stop()
                  .fadeIn()
                  .addClass(getIn);
                $(this).closest(".col-menu").addClass("on");
                return false;
              });
            });

            $(this).on("mouseleave", function () {
              $(".dropdown-menu", this).stop().removeClass(getIn);
              $(".dropdown-menu", this).stop().addClass(getOut).fadeOut();
              $(".col-menu", this)
                .find(".content")
                .stop()
                .fadeOut()
                .removeClass(getIn);
              $(".col-menu", this).removeClass("on");
              $("li.dropdown", this).removeClass("on");
              return false;
            });
          });
        } else {
          // Hover effect Default Menu
          $("nav.navbar.bootsnav ul.nav").each(function () {
            $("a.dropdown-toggle", this).off("click");
            $("a.dropdown-toggle", this).on("click", function (e) {
              e.stopPropagation();
            });

            $(".megamenu-fw", this).each(function () {
              $(".title", this).off("click");
              $("a.dropdown-toggle", this).off("click");
              $(".content").removeClass("animated");
            });

            $(".dropdown-menu", this).addClass("animated");
            $("li.dropdown", this).on("mouseenter", function () {
              $(".dropdown-menu", this).eq(0).removeClass(getOut);
              $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
              $(this).addClass("on");
              return false;
            });

            $("li.dropdown", this).on("mouseleave", function () {
              $(".dropdown-menu", this).eq(0).removeClass(getIn);
              $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
              $(this).removeClass("on");
            });

            $(this).on("mouseleave", function () {
              $(".dropdown-menu", this).removeClass(getIn);
              $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
              $("li.dropdown", this).removeClass("on");
              return false;
            });
          });
        }

        $("nav.navbar.bootsnav .attr-nav").each(function () {
          $("a.dropdown-toggle", this).off("click");
          $("a.dropdown-toggle", this).on("click", function (e) {
            e.stopPropagation();
          });

          $(".dropdown-menu", this).addClass("animated");
          $("li.dropdown", this).on("mouseenter", function () {
            $(".dropdown-menu", this).eq(0).removeClass(getOut);
            $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
            $(this).addClass("on");
            return false;
          });

          $("li.dropdown", this).on("mouseleave", function () {
            $(".dropdown-menu", this).eq(0).removeClass(getIn);
            $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
            $(this).removeClass("on");
          });

          $(this).on("mouseleave", function () {
            $(".dropdown-menu", this).removeClass(getIn);
            $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
            $("li.dropdown", this).removeClass("on");
            return false;
          });
        });
      }

      if (getNav.hasClass("navbar-full")) {
        var windowHeight = $(window).height(),
          windowWidth = $(window).width();

        $(".nav-full").css("height", windowHeight + "px");
        $(".wrap-full-menu").css("height", windowHeight + "px");
        $(".wrap-full-menu").css("width", windowWidth + "px");

        $(".navbar-collapse").addClass("animated");
        $(".navbar-toggle").each(function () {
          var getId = $(this).data("target");
          $(this).off("click");
          $(this).on("click", function (e) {
            e.preventDefault();
            $(getId).removeClass(getOut);
            $(getId).addClass("in");
            $(getId).addClass(getIn);
            return false;
          });

          $("li.close-full-menu").on("click", function (e) {
            e.preventDefault();
            $(getId).addClass(getOut);
            setTimeout(function () {
              $(getId).removeClass("in");
              $(getId).removeClass(getIn);
            }, 500);
            return false;
          });
        });
      }
    },
    navbarSticky() {
      var getNav = $("nav.navbar.bootsnav"),
        navSticky = getNav.hasClass("navbar-sticky");

      if (navSticky) {
        // Set Height Navigation
        var getHeight = getNav.height();
        $(".wrap-sticky").height(getHeight);

        // Windown on scroll
        var getOffset = $(".wrap-sticky").offset().top;
        $(window).on("scroll", function () {
          var scrollTop = $(window).scrollTop();
          if (scrollTop > getOffset) {
            getNav.addClass("sticked");
          } else {
            getNav.removeClass("sticked");
          }
        });
      }
    },
    navbarScrollspy() {
      var navScrollSpy = $(".navbar-scrollspy"),
        $body = $("body"),
        getNav = $("nav.navbar.bootsnav"),
        offset = getNav.outerHeight(),
        $position = undefined;

      if (navScrollSpy.length) {
        $body.scrollspy({ target: ".navbar", offset: offset });

        // Animation Scrollspy
        $(".scroll").on("click", function (event) {
          event.preventDefault();

          // Active link
          $(".scroll").removeClass("active");
          $(this).addClass("active");

          // Remove navbar collapse
          $(".navbar-collapse").removeClass("in");

          // Toggle Bars
          $(".navbar-toggle").each(function () {
            $(".fa", this).removeClass("fa-times");
            $(".fa", this).addClass("fa-bars");
          });

          // Scroll
          var scrollTop = $(window).scrollTop(),
            $anchor = $(this).find("a"),
            $section = $($anchor.attr("href")).offset().top,
            $window = $(window).width(),
            $minusDesktop = getNav.data("minus-value-desktop"),
            $minusMobile = getNav.data("minus-value-mobile"),
            $speed = getNav.data("speed");

          if ($window > 992) {
            $position = $section - $minusDesktop;
          } else {
            $position = $section - $minusMobile;
          }

          $("html, body").stop().animate(
            {
              scrollTop: $position,
            },
            $speed
          );
        });

        // Activate Navigation
        var fixSpy = function () {
          var data = $body.data("bs.scrollspy");
          if (data) {
            offset = getNav.outerHeight();
            data.options.offset = offset;
            $body.data("bs.scrollspy", data);
            $body.scrollspy("refresh");
          }
        };

        // Activate Navigation on resize
        var resizeTimer;
        $(window).on("resize", function () {
          clearTimeout(resizeTimer);
          var resizeTimer = setTimeout(fixSpy, 200);
        });
      }
    },
    handleResize() {
      this.hoverDropdown();
      setTimeout(function () {
        this.navbarSticky();
      }, 500);

      // Toggle Bars
      $(".navbar-toggle").each(function () {
        $(".fa", this).removeClass("fa-times");
        $(".fa", this).addClass("fa-bars");
        $(this).removeClass("fixed");
      });
      $(".navbar-collapse").removeClass("in");
      $(".navbar-collapse").removeClass("on");
      $(".navbar-collapse").removeClass("bounceIn");
    },
    pageJump(name_) {
      console.log("[ name_ ] >", name_);
      if (name_.categoryTitle) {
        this.$router.push({
          path: `/OurNews/${name_.id}`,
        });
      } else {
        this.$router.push({
          name: name_,
        });
      }
    },
    showInfo() {
      this.$message("努力开发中，敬请您的期待~", 1);
    },
  },
};
</script>

<style scoped lang="less">
/deep/.row {
  margin-right: 0px;
}
@import "./css/boot.css";
@import "./css/bootsnav.css";
.website-logo {
  margin-right: 3rem;
  .logo {
    width: 60px;
    height: 60px;
    display: inline-block;
    margin: auto 20px auto 0;
  }
  .website-name {
    font-size: 2.4rem;
    display: flex;
    align-items: center;
  }
}
.navbar-brand {
  padding: 29px 15px;
  height: auto;
}
nav.navbar.bootsnav {
  border: none;
}
.navbar-nav {
  float: left;
}
nav.navbar.bootsnav ul.nav > li > a {
  color: #474747;
  text-transform: uppercase;
  padding: 3rem;
  font-size: 17px;
}
nav.navbar.bootsnav ul.nav > li:hover {
  background: #f4f4f4;
}
.nav > li:after {
  content: "";
  width: 0;
  height: 5px;
  background: #c9adab;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.5s ease 0s;
}
.nav > li:hover:after {
  width: 100%;
}
nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle:after {
  content: "+";
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 35%;
  right: 8%;
  transition: all 0.4s ease 0s;
}
nav.navbar.bootsnav ul.nav > li.dropdown.on > a.dropdown-toggle:after {
  content: "›";
  transform: rotate(90deg);
}
.dropdown-menu.multi-dropdown {
  position: absolute;
  left: -100% !important;
}
nav.navbar.bootsnav li.dropdown ul.dropdown-menu {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border: none;
}
@media only screen and (min-width: 768px) {
  .navbar-header .logo {
    float: right;
  }
}
@media only screen and (max-width: 990px) {
  nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle:after,
  nav.navbar.bootsnav ul.nav > li.dropdown.on > a.dropdown-toggle:after {
    content: " ";
  }
  .dropdown-menu.multi-dropdown {
    left: 0 !important;
  }
  nav.navbar.bootsnav ul.nav > li:hover {
    background: transparent;
  }
  nav.navbar.bootsnav ul.nav > li > a {
    margin: 0;
  }
}
</style>
